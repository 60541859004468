import { ListItem, ListItemText } from '@mui/material';
import React from 'react';

const ContactLink = ({item}) => (
  <ListItem
    key='item.name'
    sx={{
      '& :hover': {
        cursor: 'pointer'
      }
    }}
  >
    {item.icon}
    <ListItemText
      primary={item.name}
      sx={{
        fontSize: '2em',
        color: 'blue',
        textDecoration: 'underline'
      }}
      onClick={() => window.open(`${item.action}${item.link}`, '_blank')}
    />
  </ListItem>
);

export default ContactLink;