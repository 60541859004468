import React, { useState } from 'react';
import { Box, Card, CardContent, CardHeader, CardMedia, Grid, ImageList, ImageListItem, Modal, Paper, Typography, Button } from '@mui/material';
import CodeIcon from '@mui/icons-material/Code';
import ExitToAppIcon from '@mui/icons-material/ExitToApp';
import projects from '../assets/projects';

import { styles } from './App';

const projStyles = {
  button: {
    display: 'flex',
    flexGrow: 1,
    justifyContent: 'flex-start',
    margin: 1,
  },
  buttonIcon: {
    marginRight: 1
  },
  margin2: {
    marginBottom: 2
  },
  modalBox: {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: '60%',
    bgcolor: 'background.paper',
    boxShadow: 24,
    p: 4
  }

}


const Projects = () => {

  const [modalOpen, setModalOpen] = useState(false);
  const [selectedProject, setSelectedProject] = useState(null);

  const selectProject = (project) => {
    setModalOpen(true);
    setSelectedProject(project);
  };

  const handleModalClose = () => {
    setModalOpen(false);
    setSelectedProject(null);
  };

  const ShowModal = () => {

    if (!selectedProject) {
      setModalOpen(false);
      return null;
    };

    const project = selectedProject;

    return (
      <Modal
        open={modalOpen}
        onClose={() => handleModalClose()}
      >
        <Box 
          sx={projStyles.modalBox}
        >
          <Typography
            variant='h3'
            sx={projStyles.margin2}
          >
            {project.name}
          </Typography>
          <Grid container>
            <Grid 
              item 
              sm={6}
              sx={{
                padding: 2
              }}
            >
              {project.blurb.map((para, i) => (
                <Typography
                  variant='body1'
                  sx={projStyles.margin2}
                  key={i}
                >
                  {para}
                </Typography>
              ))}

              <Button 
                variant='outlined'
                href={project.gitLink}
                target='_blank'
                rel='noreferrer'
                sx={projStyles.button}
              >
                <CodeIcon
                  sx={projStyles.buttonIcon}
                />
                <Typography>
                  Check out the code
                </Typography>
                
              </Button>
              <Button
                variant='outlined'
                href={project.appLink}
                target='_blank'
                rel='noreferrer'
                sx={projStyles.button}
              >
                <ExitToAppIcon 
                  sx={projStyles.buttonIcon}
                />
                <Typography>
                  Try the app
                </Typography>
              </Button>

              <Typography
                variant='h6'
              >
                Created using:
              </Typography>
              <ImageList
                sx={{
                  width: '100%',
                  display: 'flex',
                  flexDirection: 'row',
                  justifyContent: 'center'
                }}
              >
                {project.tech.map((item, i) => (
                  <ImageListItem 
                    key={i}
                    sx={{
                      height: '50px',
                      width: '50px',
                    }}
                  >
                    <img
                      style={{objectFit: 'contain'}}
                      src={item.img}
                      alt={item.name}
                    />
                  </ImageListItem>
                ))}
              </ImageList>
            </Grid>
            <Grid 
              item 
              sm={6}
            >
              <img 
                style={{objectFit: 'contain', maxWidth: '100%', maxHeight: '500px'}}
                src={project.image}
                alt={project.name}
              />
            </Grid>

          </Grid>
          <Typography
            sx={{
              padding: 1,
              textAlign: 'center',
              color: 'blue',
              '& :hover': {
                cursor: 'pointer'
              }
            }}
            onClick={() => handleModalClose()}
          >
            *click outside to close*
          </Typography>

        </Box>
      </Modal>
    )
  };

  const renderProjects = projects => (
    projects.map((project, i) => (
      <Grid
        item
        sm={6}
        key={i}
        sx={{
          '& :hover': {
            cursor: 'pointer',
          }
        }}
        onClick={() => selectProject(project)}
      >
        <Card
          className='project-card'
          // elevation={0}
          sx={{
            margin: 2,
            padding: 2,
          }}
          >
          <CardHeader
            title={project.name}
            />
          <CardMedia
            component='img'
            image={project.image}
            height='300'
            alt={project.name}
            sx={{
              objectFit: 'contain'
            }}
            />
          <CardContent>
            <Typography
              variant='body2'
              color='text.secondary'
              >
              {project.intro}
            </Typography>
            <Typography
              sx={{
                padding: 1,
                textAlign: 'center',
                color: 'blue',
                '& :hover': {
                  cursor: 'pointer'
                }
              }}
            >
              *click for more info*
            </Typography>
          </CardContent>
        </Card>
      </Grid>
    ))
  );

  return (
    <Box 
      className='section' 
      id='projects'
      sx={styles.section}
    >
      <Paper
        elevation={2}
        sx={styles.sectionPaper}
      >
        <Typography
          variant='h2'
        >
          Some Of My Projects
        </Typography>
        <Grid
          container
        >
          {renderProjects(projects)}
        </Grid>
      </Paper>
      <ShowModal />
    </Box>
  );
};

export default Projects;