import React from 'react';

import {createTheme, ThemeProvider } from '@mui/material';

import Layout from './Layout';
import Tech from './Tech';
import About from './About';
import Projects from './Projects';
import Contact from './Contact';
import Repos from './Repos';
import Footer from './Footer';

const theme = createTheme({
  typography: {
    fontFamily: 'Quicksand',
    fontWeightLight: 300,
    fontWeightRegular: 500,
    fontWeightMedium: 600,
    fontWeightBold: 700,
  }
});

export const styles = {
  section: {
    paddingTop: 4,
    opacity: 1
  },
  sectionPaper: {
    padding: 3,
    marginBottom: 4,
  }
}

const App = () => {
  return (
    <ThemeProvider theme={theme}>
      <Layout>
        <Contact />
        <Repos />
        <Tech />
        <About />
        <Projects />
        <Footer />
      </Layout>
    </ThemeProvider>
  );
};

export default App;