import nodeImg from './node.png';
import expressImg from './express.png';
import reactImg from './react.png';
import reduxImg from './redux.png';
import tsImg from './ts.png';
import jsImg from './js.png';
import htmlImg from './html.png';
import cssImg from './css.png';
import mysqlImg from './mysql.png';
import gitImg from './git.png';
import jqImg from './jq.png';
import officeImg from './office.png';
import phpImg from './php.png';
import rubyImg from './ruby.png';
import pgImg from './pg.png';
import muiImg from './mui.png';
import semanticImg from './semantic.png';

const skills = {
  node: {
    img: nodeImg,
    name: 'Node'
  },
  express: {
    img: expressImg,
    name: 'Express'
  },
  react: {
    img: reactImg,
    name: 'React'
  },
  redux: {
    img: reduxImg,
    name: 'Redux'
  },
  ts: {
    img: tsImg,
    name: 'Typescript'
  },
  js: {
    img: jsImg,
    name: 'Javascript'
  },
  jq: {
    img: jqImg,
    name: 'JQuery'
  },
  html: {
    img: htmlImg,
    name: 'HTML'
  },
  css: {
    img: cssImg,
    name: 'CSS'
  },
  mysql: {
    img: mysqlImg,
    name: 'MySQL'
  },
  git: {
    img: gitImg,
    name: 'Git'
  },
  mui: {
    img: muiImg,
    name: 'MaterialUI'
  },
  semantic: {
    img: semanticImg,
    name: 'SemanticUI'
  },
  php: {
    img: phpImg,
    name: 'PHP'
  },
  ruby: {
    img: rubyImg,
    name: 'Ruby'
  },
  pg: {
    img: pgImg,
    name: 'PostgreSQL'
  },
  office: {
    img: officeImg,
    name: 'MSOffice'
  }
};

export default skills;
