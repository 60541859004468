import React from 'react';

import Drawer from '@mui/material/Drawer';
import { List, ListItemButton, Box, ListItemIcon, ListItemText, Container } from '@mui/material';
import CallIcon from '@mui/icons-material/Call';
import CodeIcon from '@mui/icons-material/Code';
import HelpOutlineIcon from '@mui/icons-material/HelpOutline';
import AppsIcon from '@mui/icons-material/Apps';

const drawerWidth = 220;

const Layout = ({children}) => {

  const menuItems = [
    {
      text: 'Contact',
      icon: <CallIcon />,
      target: 'contact'
    },
    {
      text: 'Tech',
      icon: <CodeIcon />,
      target: 'tech'
    },
    {
      text: 'About',
      icon: <HelpOutlineIcon />,
      target: 'about'
    },
    {
      text: 'Projects',
      icon: <AppsIcon />,
      target: 'projects'
    },
  ];


  const handleScroll = (id) => {
    if (id === 'contact') {
      window.scrollTo(0, 0);
    } else {
      document.querySelector(`#${id}`).scrollIntoView()
    }
  };

  return (
    <Container
      sx={{
        display: 'flex',
        zIndex: 3,
      }}
    >
      <Drawer
        sx={{
          width: drawerWidth,
          '& .MuiDrawer-paper': {
            width: drawerWidth,
            boxSizing: 'border-box',
          }
        }}
        variant='permanent'
      >
        <List>
          {menuItems.map(item => (
            <ListItemButton 
              id={`${item.target}Menu`}
              key={item.text}
              onClick={() => handleScroll(item.target)}
            >
              <ListItemIcon>
                {item.icon}
              </ListItemIcon>
              <ListItemText primary={item.text} />
            </ListItemButton>
          ))}
        </List>
      </Drawer>
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'column',
          width: `calc(100% - ${drawerWidth}px)`,
          zIndex: 3,
        }}
      >
        {children}
      </Box>
    </Container>
  );
};

export default Layout;