import React from 'react';
import { Box, Card, CardMedia, Grid, Paper, Typography } from '@mui/material';
import skills from '../assets/skills';

import { styles } from './App';

const Tech = () => {

  const rows = {
    bigSkills: [
      [
        skills.node,
        skills.express,
        skills.react,
        skills.redux
      ],
      [
        skills.js,
        skills.ts,
        skills.jq
      ],
      [
        skills.html,
        skills.css,
        skills.mysql,
        skills.git
      ],
      [
        skills.mui,
        skills.semantic,
      ]
    ],
    smallSkills: [
      skills.php,
      skills.ruby,
      skills.pg,
      skills.office
    ]
  };

  const renderSkill = (item, height, fontSize, margin) => (
    <Card
      elevation={2}
      sx={{
        margin,
        padding: margin -1,
        paddingTop: margin
      }}
    >
      <CardMedia
        component='img'
        image={item.img}
        height={height}
        sx={{
          objectFit: 'contain'
        }}
      />
      <Typography
        variant='subtitle2'
        sx={{
          fontSize: `${fontSize}em`,
          textAlign: 'center',
          padding: 2
        }}
      >
        {item.name}
      </Typography>
    </Card>
  )

  return (
    <Box 
      className='section' 
      id='tech' 
      sx={styles.section}>
      <Paper
        elevation={2}
        sx={styles.sectionPaper}
      >
        <Typography
          variant='h2'
        >
          Languages,
        </Typography>
        <Typography
          variant='h2'
        >
          Frameworks,
        </Typography>
        <Typography
          variant='h2'
        >
          Tools
        </Typography>
        {rows.bigSkills.map((row, i) => (
          <Grid 
            container 
            justifyContent='center'
            sx={{
              flexGrow: 1
            }}
            key={i}
          >
            {row.map((item, i) => (
              <Grid 
                item 
                sm={3}
                key={i}
              >
                {renderSkill(item, '120', '1.2', 2)}
              </Grid>
            ))}
          </Grid>
        ))}
        <Grid
          container
          justifyContent='center'
        >
          {rows.smallSkills.map((item, i) => (
            <Grid
              item
              sm={3}
              key={i}
            >
              {renderSkill(item, '80', '0.9', 1)}
            </Grid>
          ))}
        </Grid>
      </Paper>
    </Box>
  );
};

export default Tech;