import skills from './skills';
import thingsImg from './todo2.png';
import cycleImg from './CycleTracker.jpg';
import clockImg from './clock.png';
import tttImg from './ttt3d.png';

const projects = [
  {
    name: 'Things To Do',
    intro: 'A Todo App with a Node/Express/TS/MySql server and React/TS front end styled with Material UI.',
    blurb: [
      'While busting new ground in the realms of hyper-creativity I made this Todo App.',
      'This was built using Typescript on top of both the Node/Express/MySQL server and React front end, and styled using Material UI.',
      'Once a user has created an account they can add a Todo with a due date and any sub-steps required, then see a countdown until the task is due and the number of steps completed.'
    ],
    gitLink: 'https://github.com/Iamasamwich/todo-server#readme',
    appLink: 'http://things-to-do-todo-app.herokuapp.com/',
    tech: [skills.node, skills.express, skills.ts, skills.react, skills.redux, skills.mysql, skills.mui],
    image: thingsImg 
  },
  {
    name: 'Cycle Track',
    intro: 'A mobile Fitness Tracking PWA to track performance over time and display your data as a graph.',
    blurb: [
      'I made this app to track a users fitness over time from distance based activities.',
      'A user can enter the date, time, distance, their weight, and some notes from their workout sessions, which is then displayed in graph form.',
      'Cycle Tracker was styles with Semantic UI, and uses Recharts for the graphs.',
      '** Please note that this app was designed for use on mobile only **'
    ],
    gitLink: 'https://github.com/Iamasamwich/fitness_track_server#readme',
    appLink: 'https://cycletrack.herokuapp.com/',
    tech: [skills.node, skills.express, skills.react, skills.redux, skills.mysql],
    image: cycleImg
  },
  {
    name: 'Tic Tac Toe 3D',
    intro: 'Like normal Tic Tac Toe, but 3 times as interesting!',
    blurb: [
      'This is a 3-dimensional Tic Tac Toe game, using 3 boards stacked on top of each other.',
      'Users can now get three in a row by going down through the boards, meaning there are now 49 winning combinations instead of the 8 on a single board.',
    ],
    gitLink: 'https://github.com/Iamasamwich/ttt3d#readme',
    appLink: 'https://ttt3d.herokuapp.com/',
    tech: [skills.react, skills.js, skills.html, skills.css],
    image: tttImg
  },
  {
    name: 'Clock Dinger',
    intro: 'A web-based clock app with an optional periodic chime.',
    blurb: [
      'This is a clock app, designed to be run in fullscreen mode and left on one of your workspaces.',
      'Opening the burger menu will let the user select how often they want the chime to sound: never, or every 15, 30, or 60 minutes.'
    ],
    gitLink: 'https://github.com/Iamasamwich/clock-dinger#readme',
    appLink: 'https://clockdinger.herokuapp.com',
    tech: [skills.react, skills.js, skills.html, skills.css],
    image: clockImg
  }
];

export default projects;