import React from 'react';
import { Box, List, ListItemIcon, Paper, Typography } from '@mui/material';
import PhoneAndroidOutlinedIcon from '@mui/icons-material/PhoneAndroidOutlined';
import AlternateEmailIcon from '@mui/icons-material/AlternateEmail';

import { styles } from './App';
import ContactLink from './ContactLink';

const Contact = () => {

  const contacts = [
    {
      name: '0447 464 726',
      icon: <ListItemIcon><PhoneAndroidOutlinedIcon /></ListItemIcon>,
      link: '0447 464 726',
      action: 'tel:'
    },
    {
      name: 'sam@iamasamwich.com',
      link: 'sam@iamasamwich.com',
      icon: <ListItemIcon><AlternateEmailIcon /></ListItemIcon>,
      action: 'mailto:'
    }
  ];

  return (
    <Box 
      className='section' 
      id='contact'
      sx={styles.section}
    >
      <Paper
        elevation={2}
        sx={styles.sectionPaper}
      >
        <Typography 
          align='center' 
          variant='h1'
          sx={{
            fontWeight: 'bold',
            paddingBottom: 6
          }}  
        >
          Hi, I'm Sam
        </Typography>
        <Typography
          variant='h2'
        >
          Contact Me
        </Typography>
        <List>
          {contacts.map(item => (
            <ContactLink item={item} key={item.name} />
          ))}
        </List>
      </Paper>
    </Box>
  );
};

export default Contact;