import { Box, List, ListItemIcon, Paper, Typography } from '@mui/material';
import GitHubIcon from '@mui/icons-material/GitHub';
import bb from '../assets/bb.svg';

import React from 'react';
import ContactLink from './ContactLink';

import { styles } from './App';

const Repos = () => {

  const repos = [
    {
      name: 'Github',
      link: 'github.com/iamasamwich',
      icon: <ListItemIcon><GitHubIcon /></ListItemIcon>,
      action: 'https://'
    },
    {
      name: 'Bitbucket',
      link: 'bitbucket.org/iamasamwich',
      icon: <ListItemIcon sx={{display: 'flex', height: '24px', width: '24px'}}><img style={{opacity: '0.5'}} src={bb} alt='BitBucket' /></ListItemIcon>,
      action: 'https://'
    }
  ];

  return (
    <Box
      className='section'
      id='repos'
      sx={styles.section}
    >
      <Paper elevation={2} sx={styles.sectionPaper}>
        <Typography variant='h2'>
          Repositories
        </Typography>
        <List>
          {repos.map(item => (
            <ContactLink item={item} key={item.name} />
          ))}
        </List>
      </Paper>
    </Box>
  )
};

export default Repos;