import { Typography } from '@mui/material';
import React from 'react';

const Footer = () => (
  <Typography
    variant='h6'
    color='text.secondary'
    sx={{
      textAlign: 'center',
      fontSize: '0.7em',
      backgroundColor: 'white'
    }}
  >
    Sam Humphreys 2021
  </Typography>
);

export default Footer;